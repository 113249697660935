@charset "utf-8";
/* CSS Document */

#flag {
  width: 80px;
  display: flex;
  margin-right: 10px;
  p {
    width: 50%;
    padding-top: 10px;
  }
}

#title {
  width: 100%;
  height: 80px;
  margin-top: 0px;
  margin-bottom: 40px;

  .title-en {
    font-family: 'Times New Roman', Aileron;
    font-style: italic;
    font-size: 200%;
    color: #000;
    text-align: center;
  }

  .title-jp {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-size: 140%;
    color: #000;
    padding-top: 20px;
    text-align: center;
  }
}

#image-slide {
  height: 560px;
  margin: 40px auto 0;
  max-width: 900px;

  .v-application--wrap {
    min-height: 560px;
  }
}

#news {
  max-width: 80%;
  margin: 40px auto 60px;

  .news-title {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px 0;

    img {
      height: 20px;
      width: 100%;
    }
  }

  .news-item {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 10px;
    font-size: 100%;
    border-bottom: 1px dashed;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .news-contents {
      height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .news-date {
      width: 85px;
      padding: 0 10px;
    }

    .news-sentence {
      width: 60%;
      max-width: 100%;
      flex: 1;
      word-break: break-all;
    }
  }
}

#footer {
  p {
    font-size: 95%;
    color: #fff;
    text-align: left;
  }
  .sitemap {
    margin: 20px 0;
    a {
      color: #fff;
      background-color: gray;
      border-width: 1px;
      border-style: none solid none solid;
      padding: 0 20px;
    }
  }

  #f-left {
    width: 35%;
    padding-top: 20px;
    @media screen and (max-width: 1000px) {
      width: 80%;
      justify-content: center;
      margin: 0 auto;
    }

    .contact,
    .link {
      font-size: 120%;
      color: #fff;
      margin-bottom: 16px;
    }
  }

  #f-right {
    width: 25%;
    padding-top: 20px;
    @media screen and (max-width: 1000px) {
      width: 80%;
    }
  }
}
